import React from 'react'

export interface TouchableOpacityProps{
    className?:string
    children:React.ReactNode
    onClick:(ev:any)=>void
}

export default function TouchableOpacity(props:TouchableOpacityProps) {
  const [touched, touchedSet] = React.useState(false)

//   className={classNames(styles.container, className)}


  return (
    <div
      style={{ opacity: touched ? 0.5 : 1, transition: 'opacity 300ms ease' }}
      onMouseDown={() => touchedSet(true)}
      onClick={props.onClick}
      onMouseUp={() => touchedSet(false)} >
      {props.children}
    </div>
  )
}