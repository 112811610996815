import React, { MutableRefObject } from "react";
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { useDialog, DialogsContextProps } from "../providers/dialogs";
import { useSnackBar } from "../providers/snackbar";
import type { FeatureCollection } from 'geojson'
import { validateGeoJSON } from "../utils";


export interface ImportGeoJsonDialogProps {
    value?: string;
    dialogProps?: Partial<DialogProps>
    title?: string
}

export function ImportGeoJsonDialog(props: ImportGeoJsonDialogProps) {

    const title: string = props.title || "Importar GeoJson"
    const [value, setValue] = React.useState<string>(props.value || '')
    const { dialogProps } = props;
    const dialog: DialogsContextProps = useDialog();
    const textRef: MutableRefObject<any> = React.useRef(null)
    const snackbar = useSnackBar()

    const cancel = () => {
        dialog.close()
    }

    const accept = () => {
        try {
            const parsed:FeatureCollection = JSON.parse(value)
            if(!validateGeoJSON(parsed)){
                snackbar.showError({ message: "GEOJson invalido" })
                return
            }
            dialog.close(parsed)
        } catch (err) {
            snackbar.showError({ message: "Json invalido" })
            return
        }
    }

    const onChange = (value: string) => {
        setValue(value)
    }



    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '80vh' } }}
            maxWidth="xs"
            TransitionProps={{}}
            open={true}
            {...dialogProps}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                <textarea onChange={(ev) => { onChange(ev.target.value) }} style={{ width: '90%' }} rows={20} ref={textRef} >

                </textarea>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel}>Cancelar</Button>
                <Button onClick={accept}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}
