import * as React from 'react'
import { FeatureGroup, ImageOverlay, MapContainer, Marker, Popup, Rectangle, Polygon, Polyline, useMapEvents, GeoJSON as GeoJSONComp, Tooltip, useMap, useMapEvent } from 'react-leaflet'
// import * as L from 'leaflet'
import L, { Evented, FeatureGroup as LFeatureGroup, geoJSON, LatLng, LatLngBounds, Layer, LayerGroup, Map, latLng, PathOptions } from 'leaflet'
import { GeomanControls, layerEvents, } from 'react-leaflet-geoman-v2'
import { getImageDimensions, getBounds, throttle } from '../utils'
import { GeoJSON, Geometry, Polygon as GeojsonPolygon, Feature } from "geojson"
import { Dimensions, MapFeatureCollection } from '../constants'




export interface GeomanProps {
  mapFileUrl?: string
  mapFileDimensions?: Dimensions
  geojson: MapFeatureCollection
  onMapItemClick?: (ev: any) => void
  onFeatureChange?: (ev: any) => void
  onChange?: (ev: MapFeatureCollection) => void
  mapOptions?: L.MapOptions
  ref?: any//React.MutableRefObject<GeomanHandler>
  itemPopupComponent?: (props: any) => React.ReactNode
}


export interface GeomanHandler {
  geojson: MapFeatureCollection,
  getGeojson(): MapFeatureCollection,
  setGeojson: (geojson: MapFeatureCollection) => void
  map: Map,
  setMapFileUrl: (fileUrl: string) => void
  refreshMap: () => void
  clearMap: () => void
  getLayerByUUID: (uuid: string) => L.Layer | undefined
}


// interface RenderFeatureProps {
//   feature: Feature<GeojsonPolygon>
// }
// const RenderFeature = (localProps: RenderFeatureProps) => {
//   const feature = localProps.feature
//   const isAssigned: boolean = (feature.properties as any).interactive == '1'
//   const positions: L.LatLngExpression[] = feature.geometry.coordinates[0].map(v => ({ lat: v[1], lng: v[0] }));
//   const pathOptions = isAssigned ? { color: 'blue', fillColor: 'blue' } : { color: 'gray', fillColor: 'gray' }
//   const itemName = (feature.properties as any)?.name //|| (feature.properties as any)?.uuid
//   const ref: any = React.useRef(null)
//   console.debug('RenderFeature')

//   // React.useEffect(() => {
//   //   const layer: Layer = ref.current
//   //   const callback=throttle(handleChange, 1000)
//   //   if (layer) {
//   //     layer.on('pm:edit', callback);
//   //     (layer as any).feature = feature
//   //   }
//   //   return ()=>{
//   //     layer?.off('pm:edit');
//   //   }
//   // }, [])

//   React.useEffect(() => {
//     const layer: Layer = ref.current
//     if (layer) {
//       (layer as any).feature = feature
//     }
//     return () => {

//     }
//   }, [])


//   const onFeatureClick = (ev: any) => {
//     console.debug('onFeatureClick')
//     // if (props.onMapItemClick) {
//     //   props.onMapItemClick({ layer: ev.target })
//     // }
//   }


//   return <Polygon ref={ref} pathOptions={pathOptions} positions={positions} eventHandlers={{ click: onFeatureClick }}  >
//     {itemPopupComponent &&
//       <Popup keepInView={true} minWidth={300} >
//         {itemPopupComponent({ feature: feature })}
//       </Popup>
//     }
//     {itemName &&
//       <Tooltip permanent>
//         {itemName}
//       </Tooltip>
//     }
//   </Polygon>
// }


const GeomanWrapper = React.forwardRef((props: GeomanProps, mainRef) => {
  console.debug('GeomanWrapper init')

  const emptyGeoJson: MapFeatureCollection = { type: 'FeatureCollection', features: [] }
  // const defaultMapContainerOptions: L.MapOptions = { zoom: 1, minZoom: 0, crs: L.CRS.Simple }
  const defaultMapContainerOptions: L.MapOptions = { crs: L.CRS.Simple }

  const [geojson, setGeojson] = React.useState<MapFeatureCollection>(props.geojson || emptyGeoJson)
  const geoRef = React.useRef<LayerGroup>(null)

  const [mapFileUrl, setMapFileUrl] = React.useState<string | undefined>(props.mapFileUrl)
  const [imageOverLayBounds, setImageOverLayBounds] = React.useState<L.LatLngBoundsExpression>()
  const [mapOptions, setMapOptions] = React.useState<L.MapOptions>(defaultMapContainerOptions)
  const [isInited, setIsInited] = React.useState<boolean>(false)
  const itemPopupComponent = props.itemPopupComponent

  const map: Map = useMap()


  const setBackgroundImage = async (imageUrl: string) => {
    let width: number
    let height: number
    if (props.mapFileDimensions) {
      width = props.mapFileDimensions.width
      height = props.mapFileDimensions.height
    } else {
      const dims = await getImageDimensions(imageUrl);
      width = dims.width
      height = dims.height
    }
    const bounds = getBounds(width, height);
    // console.debug('bounds:', bounds)
    // setImageOverLayBounds(bounds)
    map.setMaxBounds(bounds)
    map.fitBounds(bounds)
  }

  const getLayerByUUID = (uuid: string): L.Layer | undefined => {
    if (!geoRef.current) {
      return
    }
    const layers = geoRef.current.getLayers()
    // console.debug('layers:', layers)
    const res = layers.find((l: any) => (l as any).feature?.properties?.uuid == uuid)
    return res
  }

  const refreshMap = () => {
    // clearMap()
    setGeojson({ ...emptyGeoJson })
    setTimeout(() => {
      setGeojson({ ...geojson })
    }, 100);
  }

  const getGeojson = (): MapFeatureCollection => {
    return geoRef.current?.toGeoJSON() as MapFeatureCollection
  }


  React.useImperativeHandle(mainRef, (): GeomanHandler => {
    return {
      geojson: geojson,
      map: map,
      setGeojson: setGeojson,
      setMapFileUrl: setMapFileUrl,
      clearMap: clearMap,
      refreshMap: refreshMap,
      getLayerByUUID: getLayerByUUID,
      getGeojson: getGeojson
    }
  }, [map, mapFileUrl, geojson, imageOverLayBounds])


  React.useEffect(() => {
    if (map && mapFileUrl) {
      setBackgroundImage(mapFileUrl)
    }
    return () => {
      console.debug('map remove')
      // map.remove();
    }
  }, [map, mapFileUrl])

  React.useEffect(() => {
    console.debug('geojson changed', props.geojson)
    setGeojson(props.geojson)
    redrawMap()
  }, [props.geojson])


  // React.useEffect(() => {
  //   console.debug('geoRef')
  //   if (geoRef.current) {
  //     // console.debug('georef:', geoRef.current)
  //     // geoRef.current.on('pm:create', (e) => {
  //     //   const { layer } = e;
  //     //   console.log('pm:create:', layer);
  //     // });  
  //     geoRef.current.on('pm:edit', handleChange);
  //     // geoRef.current.on('pm:remove',handleChange);
  //   }
  //   return () => {
  //     geoRef.current?.off('pm:edit', handleChange);
  //     // geoRef.current?.off('pm:remove', handleChange);
  //   }
  // }, [geoRef])



  const clearMap = () => {
    setGeojson(emptyGeoJson)
    geoRef.current?.clearLayers()
  }

  const handleChange = (ev: any) => {
    console.debug('handleChange:', ev)
    if (!geoRef.current) {
      return
    }

    const newGeo: any = map.pm
      .getGeomanLayers(true)
      .toGeoJSON();

    // const newGeo: any = geoRef.current.toGeoJSON();

    console.debug('newGeo:', newGeo)
    setGeojson({ ...newGeo })
    if (props.onChange) {
      props.onChange(newGeo)
    }

    const layer: Layer = ev.layer

    if (layer) {
      if (ev.type == 'pm:create') {
        setupLayerEvents(layer)
      }
      redrawLayer(layer)
    }

    // redrawMap()


  }

  const assignedStyle = { color: 'blue', fillColor: 'blue' }
  const notAssignedStyle = { color: '#2596be', fillColor: 'gray' }


  const redrawLayer = (layer: Layer) => {
    const feature = (layer as any).feature
    const itemName = (feature?.properties as any)?.name //|| (feature.properties as any)?.uuid
    if (itemName) {
      layer.bindTooltip(itemName, { permanent: true })
    } else {
      layer.unbindTooltip()
    }

    if ((layer as any).feature) {
      layer.closeTooltip()
      layer.openTooltip()
    } else {
      // (layer as any).setStyle({ color: 'gray', fillColor: 'gray' })
      if((layer as any).setStyle){
        (layer as any).setStyle(notAssignedStyle)
      }      
    }
  }

  const setupLayerEvents = (layer: Layer) => {
    layer.on('click', onLayerClick);
  }

  const redrawMap = () => {
    const layers: Layer[] = Object.values((map.pm.getGeomanLayers(true) as any)._layers)
    for (let layer of layers) {
      redrawLayer(layer)
    }
  }



  const eventDebugFn = (ev: any) => {
    //  console.debug(ev)
  }

  const onEachFeature = (feature: Feature, layer: Layer) => {
    // console.debug('onEachFeature:',feature)
    const itemName = (feature.properties as any)?.name //|| (feature.properties as any)?.uuid
    // layer.on('pm:edit', throttle(handleChange, 1000));
    if (itemName) {
      layer.bindTooltip(itemName, { permanent: true })
    }
    setupLayerEvents(layer)
  }

  const styleFunction = (feature: any): PathOptions => {
    const isAssigned: boolean = (feature.properties as any).interactive == '1'
    const pathOptions = isAssigned ? assignedStyle : notAssignedStyle
    return pathOptions
  }

  const onLayerClick = (ev: any) => {
    // console.debug('onLayerClick', ev)
    if (props.onMapItemClick) {
      props.onMapItemClick({ layer: ev.target })
    }
  }



  return (
    <FeatureGroup ref={geoRef as any} eventHandlers={{}} >

      <GeomanControls
        options={{
          position: 'topleft',
          drawText: true
        }}
        globalOptions={{
          continueDrawing: false,
          editable: false,
          snappable: true,
          markerStyle: {},
          pinning: true
        }}
        // onMount={() => L.PM.setOptIn(true)}
        // onUnmount={() => L.PM.setOptIn(false)}
        eventDebugFn={eventDebugFn}
        onCreate={throttle(handleChange, 500)}
        onUpdate={handleChange}
        onChange={handleChange}
        onEdit={handleChange}
        onMapRemove={handleChange}
        onMapCut={handleChange}
        onDragEnd={handleChange}
        onMarkerDragEnd={handleChange}

      />
      <GeoJSONComp ref={geoRef as any} data={geojson} style={styleFunction} onEachFeature={onEachFeature} />
      {/* {
        geojson.features.map((feature, idx) => (
          <RenderFeature key={idx} feature={feature} />
        ))
      } */}
    </FeatureGroup>
  )


});


export default GeomanWrapper


