import {
  Link,
  useLoaderData
} from "react-router-dom";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemButton, ListItemText } from "@mui/material";
import { PlaceModel } from "../../models/place";
import { Grid } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useAuth, useCurrentUser } from "../../providers/auth";

export function PlaceListPage() {
  const routeData: any = useLoaderData();
  const places: PlaceModel[] = routeData['places']
  const user = useCurrentUser()
  console.debug('currentUser:',user)  
  console.debug('routeData:', routeData)
  return (
    <>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item>
          <Link to={'/'}>
            <ChevronLeftIcon />
          </Link>
        </Grid>
        <Grid item >
          <h3>Listado centros</h3>
        </Grid>
      </Grid>
      <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
        {places.map((place) => (
          <Link to={'/places/' + place.id}  key={place.id}>
            <ListItem>
              <ListItemText primary={place.name} />
            </ListItem>
          </Link>
        ))}
      </List>
    </>
  )
}
