
import { CircularProgress, Grid } from '@mui/material';
import React, { CSSProperties } from 'react';


const styles: { [key: string]: CSSProperties } = {
  rootview: {
    flex: 1,
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageLogo: {
    marginTop: 25,
    marginBottom: 5,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}


export function AppSplashScreen() {
  return (
    <Grid container height={'100vh'} alignSelf={'center'} alignItems={'center'} justifyContent={'center'}>
      <Grid item textAlign={'center'}>
        <div style={{ marginBottom: 10 }}>
          <img src='/android-chrome-192x192.png' />
        </div>
        <CircularProgress size={60} />
      </Grid>
    </Grid>
  );
}