import { useRouteError } from "react-router-dom";


export interface ErrorPageProps{

}

const getErrorMessage=(error:any):string=>{
  if(typeof error=='string'){
    return error
  }

  return error.statusText || error.message || "Error"


}

export function ErrorPage() {
    const error:any = useRouteError();
    const errorMessage:string=getErrorMessage(error)

    return (
      <div id="error-page" style={{padding:'10px'}}>
        <h1>Oops!</h1>
        <p>Lo sentimos, se ha producido un error.</p>
        <p>
          Cod. error: <i>{errorMessage}</i>
        </p>
      </div>
    );
  }