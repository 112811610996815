import React from "react";
import { CurrentPlaceProvider } from "../providers/currentPlace";
import "../App.css";

import {
    Outlet,
    Location,
    useLocation,
    useNavigate
} from "react-router-dom";
import { SnackBarProvider } from "../providers/snackbar";
import DialogProvider from "../providers/dialogs";
import AlertsProvider from "../providers/alerts";
import { GlobalLoaderProvider } from "../providers/globalLoader";
import AuthProvider from "../providers/auth";

export default function RootPage() {
    // console.debug('RootPage')

    const location: Location = useLocation()
    const navigate = useNavigate();
    React.useEffect(() => {
        if (location.pathname == '/') {
            navigate("/places");
        }
    }, [location])

    return (
        <SnackBarProvider>
            <DialogProvider>
                <AlertsProvider>
                    <AuthProvider>
                        <CurrentPlaceProvider>
                            <GlobalLoaderProvider>
                                <div style={{ padding: 10 }}>
                                    <Outlet />
                                </div>
                            </GlobalLoaderProvider>
                        </CurrentPlaceProvider>
                    </AuthProvider>
                </AlertsProvider>
            </DialogProvider>
        </SnackBarProvider>
    )
}
