
import { Alert, AlertProps, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import { DialogParams, useDialog } from "./dialogs";



export interface ShowAlertOptions {
    title?: string,
    message: string,
    severity?: 'success' | 'info' | 'warning' | 'error'
    cancelButtonLabel?: string
    alertOptions?: AlertProps
}

export interface AlertContextProps {
    show: (options: ShowAlertOptions) => void,
    showError: (options: ShowAlertOptions) => void,
    hide: () => void,
    hideAll: () => void
}

const AlertsContext = React.createContext<AlertContextProps | undefined>(undefined);

export function useAlerts(): AlertContextProps {
    const ctx: AlertContextProps | undefined = React.useContext(AlertsContext)
    if (!ctx) {
        throw new Error("No se puede utilizar AlertsContext sin provider")
    }
    return ctx
}


type AlertParams = {
    children: React.ReactNode;
    open: boolean;
    onClose?: Function;
    onExited?: Function;
};

export default function AlertsProvider({ children }: { children: React.ReactNode }) {
    // const [alerts, setAlerts] = React.useState<AlertParams[]>([]);
    const [dialogs, setDialogs] = React.useState<DialogParams[]>([]);

    const defaultOptions: Partial<ShowAlertOptions> = { cancelButtonLabel: 'Ok' }

    const dialogsProvider = useDialog()

    const getDefaultTitle = (options: AlertProps): string => {
        //'success' | 'info' | 'warning' | 'error'
        switch (options.security) {
            case 'success':
                return 'Operacion correcta'
            case 'info':
                return 'Info'
            case 'warning':
                return 'Advertencia'
            case 'error':
                return 'Error'
            default:
                return 'Info'
        }

    }

    const showAlert = (options: ShowAlertOptions) => {
        options = { ...defaultOptions, ...options }
        const dialogProps = {}
        const title = options.title || getDefaultTitle(options.alertOptions || {})
        const el = (
            <Dialog open {...dialogProps}
                sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '80vh' } }}
                maxWidth="xs" >
                {title && <DialogTitle>{title}</DialogTitle>}
                <DialogContent dividers>
                    {options.message}
                </DialogContent>
                <DialogActions>
                    <Button onClick={hideAlert}>{options?.cancelButtonLabel}</Button>
                </DialogActions>
            </Dialog>
        )
        const dialog: DialogParams = dialogsProvider.open({ children: el })
        dialogs.push(dialog)
        setDialogs([...dialogs])
    };

    const showError = (options: ShowAlertOptions) => {
        showAlert({ title: 'Error', ...options, severity: 'error' })
    }

    const hideAlert = () => {
        console.debug('hideAlert:', dialogs)
        const latestDialog = dialogs.pop()
        if (latestDialog) {
            dialogsProvider.close(latestDialog)
            setDialogs([...dialogs])
        }
    };

    const hideAllAlerts = () => {
        for(let dialog of dialogs){
            dialogsProvider.close(null,dialog)
        }
        setDialogs([])
    };


    const contextValue: AlertContextProps = { show: showAlert, showError: showError, hide: hideAlert, hideAll: hideAllAlerts }

    return (
        <AlertsContext.Provider value={contextValue}>
            {children}
        </AlertsContext.Provider>
    );
}