import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Button, Divider, ListItemIcon, ListItemText } from "@mui/material";
import { SpaceModel } from "../models/space";
// import InboxIcon from '@mui/icons-material/Inbox';
import CheckIcon from '@mui/icons-material/Check';
import { FeatureItemModel } from '../models/featureItem';

export type BoxListItemAction='assign'|'unassign'

export interface BoxesListProps {
    items: SpaceModel[]
    featureItems: FeatureItemModel[]
    activeItemId?: string
    onItemClick?: (item: SpaceModel,action:BoxListItemAction) => void
    onItemMouseEnter?: (item: SpaceModel) => void
    onItemMouseLeave?: (item: SpaceModel) => void
}

export function BoxesList(props: BoxesListProps) {
    const boxes: SpaceModel[] = props.items
    const onItemMouseEnter=props.onItemMouseEnter
    const onItemMouseLeave=props.onItemMouseLeave

    const onAssignClick = (item: SpaceModel) => {
        if (props.onItemClick) {
            props.onItemClick(item,'assign')
        }
    }
    const onUnassignClick = (item: SpaceModel) => {
        if (props.onItemClick) {
            props.onItemClick(item,'unassign')
        }
    }

    const isItemActive = (item: SpaceModel): boolean => {
        return (props.activeItemId && props.activeItemId == item.id) ? true : false
    }

    const getFeatureItem = (item: SpaceModel): FeatureItemModel | undefined => {
        return (props.featureItems || []).find(i => i.space?.id == item.id)
    }

    const isItemAssigned = (item: SpaceModel): boolean => {
        const featureItem: FeatureItemModel | undefined = getFeatureItem(item)
        if (featureItem) {
            return true
        } else {
            return false
        }
    }



    const Item = (props: { item: SpaceModel }) => {
        const box: SpaceModel = props.item
        const isAssigned: boolean = isItemAssigned(props.item)
        const isActive: boolean = isItemActive(props.item)

        const getClassName = (): string => {
            const parts: string[] = []
            if (isAssigned) {
                parts.push('item-assigned')
            }

            if (isActive) {
                parts.push('item-selected')
            }
            return parts.join(" ")
        }

        const onMouseEnter=(ev:any)=>{
            if(onItemMouseEnter){
                onItemMouseEnter(box)
            }           
            // if(!isAssigned){
            //     return
            // }
            // console.debug('onMouseEnter:',ev)
        }
        const onMouseLeave=(ev:any)=>{
            if(onItemMouseLeave){
                onItemMouseLeave(box)
            }
            // if(!isAssigned){
            //     return
            // }
            // console.debug('onMouseEnter:',ev)
        }



        return (
            <ListItem style={{ padding: 10 }} selected={isActive || isAssigned} className={getClassName()} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                <ListItemText primary={box.name} secondary={box.getAteneStatusString()} />
                {isAssigned &&
                    <>
                        <ListItemIcon>
                            <CheckIcon />
                        </ListItemIcon>
                        <Button variant="outlined" onClick={(ev) => { onUnassignClick(box) }}>Desasignar</Button>
                    </>                }
                {!isAssigned &&
                    <>
                        <Button variant="outlined" onClick={(ev) => { onAssignClick(box) }}>Asignar</Button>
                    </>
                }
            </ListItem>
        )
    }


    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {boxes.map((box) => (
                <div key={box.id} >
                    <Item item={box} />
                    <Divider />
                </div>
            ))}
        </List>
    )
}
