import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { useDialog, DialogsContextProps } from "../providers/dialogs";
import { useSnackBar } from '../providers/snackbar';


export interface SVGPreviewDialogProps {
    svg: string;
    dialogProps?: Partial<DialogProps>
    title?: string
}

export function SVGPreviewDialog(props: SVGPreviewDialogProps) {

    const title: string = props.title || "Previsualizar SVG"
    const svg: string = props.svg
    const dataUrl = `data:image/svg+xml;utf8,${svg}`
    const { dialogProps } = props;
    const dialog: DialogsContextProps = useDialog();
    const snackbar = useSnackBar()


    const closeDialog = () => {
        dialog.close()
    }

    const copySVG = () => {
        navigator.clipboard.writeText(svg);
        snackbar.show({ message: "SVG copiado al portapapeles" })
    }


    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { maxWidth: '80vw', maxHeight: '80vh', minWidth: '80vw', minHeight: '80vh' } }}
            maxWidth="xs"
            TransitionProps={{}}
            open={true}
            {...dialogProps}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                <img src={dataUrl} style={{ minWidth: 700, minHeight: 700 }} />
            </DialogContent>
            <DialogActions>
                <Button onClick={copySVG}>Copiar SVG</Button>
                <Button onClick={closeDialog}>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}
