import {
  Navigate,
  Outlet
} from "react-router-dom";
import { useAuth } from "../../providers/auth";
import { Button, ListItemButton, ListItemText, Menu, MenuItem, MenuList, Paper } from "@mui/material";
import React from "react";
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';

export function PlacesRootPage() {

  const auth = useAuth()
  const currentUser = auth.state?.user
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    auth.logout()
  }


  if (!currentUser) {
    return <Navigate to={'/login'} />
  }


  return (
    <div>
      <div style={{ textAlign: 'right', backgroundColor: 'rgba(200,200,200,.1)', padding: 10 }}>

        <Button
          id="demo-positioned-button"
          aria-controls={isMenuOpen ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuOpen ? 'true' : undefined}
          onClick={handleMenuClick}
        >
          {currentUser.get('username')}
          <PersonIcon />
        </Button>

        <Paper sx={{ width: 320 }}>
          <Menu open={isMenuOpen} onClose={handleMenuClose} anchorEl={anchorEl}>
            <MenuList dense>
              <MenuItem>
                <ListItemButton onClick={logout}>
                    Cerrar sesion
                </ListItemButton>
              </MenuItem>
            </MenuList>
          </Menu>
        </Paper>


      </div>
      <Outlet />
    </div>
  )
}