import React from "react"
import { PlaceModel } from "../models/place"


export type CurrentPlaceContextProps = {
    currentPlace: PlaceModel | undefined
    setCurrentPlace(place: PlaceModel): void
}

const CurrentPlaceContext = React.createContext<CurrentPlaceContextProps | undefined>(undefined);
export interface PlacesProviderProps {
    children?: React.ReactNode
}

export function useCurrentPlaceContext(): CurrentPlaceContextProps {
    const ctx: CurrentPlaceContextProps | undefined = React.useContext(CurrentPlaceContext)
    if (!ctx) {
        throw new Error("No se puede utilizar PlacesContext sin provider")
    }
    return ctx
}

// export function getCurrentPlace(): PlaceModel | undefined {
//     const ctx: CurrentPlaceContextProps | undefined = React.useContext(CurrentPlaceContext)
//     if (!ctx) {
//         return
//     }
//     return ctx.currentPlace
// }

// export function useCurrentPlace(): PlaceModel | undefined {
//     const place: PlaceModel | undefined = getCurrentPlace()
//     if (!place) {
//         throw new Error("No se puede usar currentPlace (valor nulo)")
//     }
//     return place
// }


export const CurrentPlaceProvider = (props: PlacesProviderProps) => {

    const [currentPlace, setCurrentPlace] = React.useState<PlaceModel>();
    // const [viewMode, setViewMode] = React.useState<ViewMode>(ViewMode.CONTENT);
    // const [errorMessage, setErrorMessage] = React.useState<string>('');    


    const value: CurrentPlaceContextProps = { currentPlace: currentPlace, setCurrentPlace: setCurrentPlace }

    return (
        <CurrentPlaceContext.Provider value={value}>
            {props.children}
        </CurrentPlaceContext.Provider>
    )
}