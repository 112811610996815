import Parse from 'parse';
import { AteneaBoxStatus, BoxStatus, ParseQueryParams } from "../constants"
import { PlaceModel } from "./place"

export interface GetSpacesQueryParams extends ParseQueryParams{
    placeId?:string
}

export class SpaceModel extends Parse.Object {

    static defaultFindIncludes = []

    static getAll(params: GetSpacesQueryParams): Promise<SpaceModel[]> {
        const query = new Parse.Query(this)
        const defaultParams: Partial<GetSpacesQueryParams> = { sort: { field: 'canonical', direction: 'asc' } }
        params = Object.assign({}, defaultParams, params)

        if (params.placeId) {
            const placeQuery = new Parse.Query('Place').equalTo('objectId',params.placeId)
            query.matchesQuery('place',placeQuery)
        }

        if (params.sort) {
            if (params.sort.direction == 'asc') {
                query.ascending(params.sort.field)
            } else {
                query.descending(params.sort.field)
            }
        }

        if (params.limit) {
            query.limit(params.limit)
        }

        return query.find()
    }

    static convertAteneaStatus(remoteStatus: string): AteneaBoxStatus {
        if (remoteStatus == 'available') {
            return AteneaBoxStatus.AVAILABLE
        }
        if (remoteStatus == 'rented') {
            return AteneaBoxStatus.RENTED
        }
        if (remoteStatus == 'maintenance') {
            return AteneaBoxStatus.MAINTENANCE
        }
        if (remoteStatus == 'reserved') {
            return AteneaBoxStatus.RESERVED
        }
        return AteneaBoxStatus.UNKNOWN
    }

    constructor(attr = {}) {
        super('Space', attr);
    }

    getDefaultAcl() {
        const acl = new Parse.ACL()
        acl.setPublicReadAccess(true)
        acl.setRoleWriteAccess('Admin', true)
        return acl
    }

    get place(): PlaceModel {
        return this.get('place');
    }

    set place(val: PlaceModel) {
        this.set('place', val);
    }

    get ateneaId(): string {
        return this.get('ateneaId');
    }

    set ateneaId(val: string) {
        this.set('ateneaId', val);
    }

    get ateneaStatus(): AteneaBoxStatus {
        return this.get('ateneaStatus');
    }

    set ateneaStatus(val: AteneaBoxStatus) {
        this.set('ateneaStatus', val);
    }

    get name(): string {
        return this.get('name');
    }

    set name(val: string) {
        this.set('name', val);
    }

    get status(): BoxStatus {
        return this.get('status');
    }

    set status(val: BoxStatus) {
        this.set('status', val);
    }

    get meters(): number {
        return this.get('meters');
    }

    set meters(val: number) {
        this.set('meters', val);
    }

    get isAvailable(): boolean {
        return this.ateneaStatus == AteneaBoxStatus.AVAILABLE
    }

    get isRented(): boolean {
        return this.ateneaStatus == AteneaBoxStatus.RENTED
    }

    hide() {
        return this.save({ status: BoxStatus.HIDDEN }, { useMasterKey: true })
    }

    unhide() {
        return this.save({ status: BoxStatus.NORMAL }, { useMasterKey: true })
    }

    get isHidden(): boolean {
        return this.status == BoxStatus.HIDDEN
    }

    getAteneStatusString(): string {
        if (this.ateneaStatus == AteneaBoxStatus.AVAILABLE) {
            return 'Disponible'
        }
        if (this.ateneaStatus == AteneaBoxStatus.RENTED) {
            return 'Alquilado'
        }
        if (this.ateneaStatus == AteneaBoxStatus.MAINTENANCE) {
            return 'Mantenimiento'
        }
        if (this.ateneaStatus == AteneaBoxStatus.RESERVED) {
            return 'Reservado'
        }
        return '-'
    }

}

Parse.Object.registerSubclass('Space', SpaceModel)
