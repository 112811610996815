import { CSSProperties } from "react";
import { MyUser } from "./models/user";
import type { FeatureCollection, Polygon as GeojsonPolygon } from 'geojson'

export type SortDirection = "asc" | 'desc'


export enum ViewMode {
  'LOADING' = 'LOADING',
  'CONTENT' = 'CONTENT',
  'EMPTY' = 'EMPTY',
  'ERROR' = 'ERROR'
}

export interface ParseQueryParams {
  query?: string;
  page?: number;
  limit?: number;
  status?: string;
  exclude?: string[];
  include?: string | string[]
  user?: MyUser | Parse.Pointer;
  sort?: { field: string, direction: SortDirection };
}

export interface ParseQueryResult<T> {
  count: number;
  results: T[];
  totalPages: number
}

export enum AteneaBoxStatus {
  AVAILABLE = 1,
  RENTED = 2,
  MAINTENANCE = 3,
  RESERVED = 4,
  UNKNOWN = 5
}

export enum BoxStatus {
  NORMAL = 1,
  HIDDEN = 2
}

export enum TGeometryType {
  POLYGON = 'Polygon'
}



export type MapFeatureCollection = FeatureCollection<GeojsonPolygon>


export interface MapContainerOptions {
  zoom?: number
  // bounds?: LatLngBoundsExpression;
  // boundsOptions?: FitBoundsOptions;
  className?: string
  id?: string
  style?: CSSProperties
  pmIgnore?: boolean
}


export interface Dimensions{
  width: number
  height: number
}


export interface IUserData{
  name: string
  uuid: string
}