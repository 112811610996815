import { IconButton, Snackbar, SnackbarProps } from "@mui/material";
import React from "react"
import CloseIcon from '@mui/icons-material/Close';

export interface SnackbarExtra {
    closeButton?: boolean
}

export type SnackBarContextProps = {
    show: (props: SnackbarProps) => void,
    showSuccess: (props: SnackbarProps) => void,
    showWarning: (props: SnackbarProps) => void,
    showError: (props: SnackbarProps) => void,
    hide: () => void
}

const SnackBarContext = React.createContext<SnackBarContextProps | undefined>(undefined);
export interface SnackBarContextPropsProps {
    children?: React.ReactNode
}

export function useSnackBar(): SnackBarContextProps {
    const ctx: SnackBarContextProps | undefined = React.useContext(SnackBarContext)
    if (!ctx) {
        throw new Error("No se puede utilizar SnackBarContext sin provider")
    }
    return ctx
}

export const SnackBarProvider = (props: SnackBarContextPropsProps) => {

    const [isOpen, seIsOpen] = React.useState<boolean>(false);
    const [snackbarProps, setSnackbarProps] = React.useState<SnackbarProps>({});
    const defaultOptions: SnackbarProps = {anchorOrigin:{  vertical: 'top', horizontal: 'center' }}
    const defaultExtra: SnackbarExtra = { closeButton: true }

    const show = (opts: SnackbarProps, extra?: SnackbarExtra) => {
        opts = { ...defaultOptions, ...opts }
        extra = { ...extra, ...defaultExtra }
        if (extra.closeButton) {
            const closeAction = <React.Fragment>
                <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={hide}
                >
                    <CloseIcon fontSize="small" />
                </IconButton>
            </React.Fragment>
            opts.action = closeAction
        }
        setSnackbarProps(opts)
        seIsOpen(true)
    }

    const showSuccess = (opts: SnackbarProps, extra?: SnackbarExtra) => {
        //todo
        show(opts, extra)
    }

    const showWarning = (opts: SnackbarProps, extra?: SnackbarExtra) => {
        //todo
        show(opts, extra)
    }

    const showError = (opts: SnackbarProps, extra?: SnackbarExtra) => {
        //todo
        show(opts, extra)
    }

    const hide = () => {
        setSnackbarProps({})
        seIsOpen(false)
    }

    const value: SnackBarContextProps = { show: show,showSuccess:showSuccess,showWarning:showWarning, showError: showError, hide: hide }


    return (
        <SnackBarContext.Provider value={value}>
            <Snackbar {...snackbarProps} open={isOpen} />
            {props.children}
        </SnackBarContext.Provider>
    )
}