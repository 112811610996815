import ReactDOM from 'react-dom/client';
import './index.css';

import reportWebVitals from './reportWebVitals';

import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import 'leaflet/dist/leaflet.css'
// import 'bootstrap/dist/css/bootstrap.min.css';

import {
  createBrowserRouter,
  Route,
  RouterProvider,
  createRoutesFromElements
} from "react-router-dom";

import RootPage from './pages/Root';
import PlaceDetailsPage from './pages/places/details';
import { initParse } from './utils';
import { placesLoader, placeLoader } from './loaders';
import { ErrorPage } from './components/ErrorPage';
import { PlacesRootPage } from './pages/places/root';
import { PlaceListPage } from './pages/places/list';
// import AuthProvider from 'react-auth-kit';
import LoginPage from './pages/auth/Login';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);


initParse(String(process.env.REACT_APP_PARSE_API_URL), String(process.env.REACT_APP_PARSE_APP_ID))

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootPage />} id='root' errorElement={<ErrorPage />} >
      <Route path='/login' element={<LoginPage />} />
      <Route path="places" element={<PlacesRootPage />}  >
        <Route path="" element={<PlaceListPage />} loader={placesLoader} />
        <Route path=":placeId" element={<PlaceDetailsPage />} loader={placeLoader} />
      </Route>
    </Route>
  )
);

root.render(
    <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
