import { LinearProgress,  LinearProgressProps } from "@mui/material";
import React from "react"

export interface GlobalLoaderExtra {
   
}

export type GlobalLoaderContextProps = {
    show: (props?: LinearProgressProps) => void,
    hide: () => void
}

const GlobalLoaderContext = React.createContext<GlobalLoaderContextProps | undefined>(undefined);
export interface GlobalLoaderContextPropsProps {
    children?: React.ReactNode
}

export function useGlobalLoader(): GlobalLoaderContextProps {
    const ctx: GlobalLoaderContextProps | undefined = React.useContext(GlobalLoaderContext)
    if (!ctx) {
        throw new Error("No se puede utilizar GlobalLoaderContext sin provider")
    }
    return ctx
}

export const GlobalLoaderProvider = (props: GlobalLoaderContextPropsProps) => {

    const [isOpen, seIsOpen] = React.useState<boolean>(false);
    const [lpProps, setLpProps] = React.useState<LinearProgressProps>({});
    const defaultOptions: LinearProgressProps = {}
    const defaultExtra: GlobalLoaderExtra = {}

    const show = (opts?: LinearProgressProps, extra?: GlobalLoaderExtra) => {
        opts = { ...defaultOptions, ...opts }
        extra = { ...extra, ...defaultExtra }     
        setLpProps(opts)
        seIsOpen(true)
    }
   

    const hide = () => {
        setLpProps({})
        seIsOpen(false)
    }

    const value: GlobalLoaderContextProps = { show: show,  hide: hide }


    return (
        <GlobalLoaderContext.Provider value={value}>
            {isOpen && <LinearProgress {...lpProps}  />}            
            {props.children}
        </GlobalLoaderContext.Provider>
    )
}