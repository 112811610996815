// import { Dialog, IconButton, Snackbar, DialogProps as DialogPropsBase } from "@mui/material";
// import React from "react"
// import CloseIconfrom from '@mui/icons-material/Close';
// import { publish, subscribe, unsubscribe } from "../events";

// export interface DialogProps extends DialogPropsBase {

// }

// export type DialogsContextProps = {
//     openViaComponent: (componentFn: (props: any) => React.ReactNode, componentOptions: any, dialogProps: Partial<DialogProps>) => DialogRef,
//     closeAll: () => void
// }

// const DialogsContext = React.createContext<DialogsContextProps | undefined>(undefined);
// export interface DialogsContextPropsProps {
//     children?: React.ReactNode
// }

// export function useDialogs(): DialogsContextProps {
//     const ctx: DialogsContextProps | undefined = React.useContext(DialogsContext)
//     if (!ctx) {
//         throw new Error("No se puede utilizar SnackBarContext sin provider")
//     }
//     return ctx
// }

// export interface DialogRefOptions {
//     idx: number,
//     componentFn: (props: any) => React.ReactNode, 
//     componentOptions: any,
//     dialogProps: Partial<DialogProps>
// }

// export enum DialogEvents {
//     OPEN = "DialogOpen",
//     CLOSE = "DialogClose"
// }



// export class DialogRef {

//     // component: React.ReactNode
//     options: DialogRefOptions

//     constructor(options: DialogRefOptions) {
//         this.options = options
//     }

//     open() {
//         publish(DialogEvents.OPEN, { ref: this })
//     }

//     close() {
//         publish(DialogEvents.CLOSE, { ref: this })
//     }


//     getIndex(): number {
//         return this.options.idx
//     }

//     setIndex(idx: number) {
//         this.options.idx = idx
//     }




// }

// export const DialogsProvider = (props: DialogsContextPropsProps) => {

//     // const [isOpen, seIsOpen] = React.useState<boolean>(false);
//     const defaultOptions: Partial<DialogProps> = {}
//     const [dialogs, setDialogs] = React.useState<DialogRef[]>([])


//     const openViaComponent = (componentFn: (props: any) => React.ReactNode, componentOptions: any, dialogProps: Partial<DialogProps>): DialogRef => {
//         const totalDialogs = dialogs.length
//         dialogProps = { ...defaultOptions, ...dialogProps }
//         const ref: DialogRef = new DialogRef({ idx: totalDialogs, dialogProps: dialogProps,componentFn:componentFn,componentOptions:componentOptions })
//         ref.open()
//         return ref
//     }

//     const closeAll = () => {
//         for (let ref of dialogs) {
//             ref.close()
//         }
//     }

//     const rebuildIndices = (dialogs: DialogRef[]) => {
//         let idx = 0
//         for (let dialog of dialogs) {
//             dialog.setIndex(idx)
//             idx++
//         }
//         return dialogs
//     }

//     React.useEffect(() => {

//         const openListener = (ev: any) => {
//             const ref: DialogRef = ev.detail.ref
//             console.debug('openListener:', ref)
//             dialogs.push(ref)
//             rebuildIndices(dialogs)
//             setDialogs([...dialogs])
//         }

//         subscribe(DialogEvents.OPEN, openListener)

//         const closeListener = (ev: any) => {
//             const ref: DialogRef = ev.detail.ref
//             const refIdx: number = ref.getIndex()
//             dialogs.splice(refIdx, 1)
//             rebuildIndices(dialogs)
//             setDialogs([...dialogs])
//         }

//         subscribe(DialogEvents.CLOSE, closeListener)

//         return () => {
//             unsubscribe(DialogEvents.OPEN, openListener)
//             unsubscribe(DialogEvents.CLOSE, closeListener)
//         }

//     }, [])


//     const renderComponent=(ref:DialogRef):React.ReactNode=>{
//         return ref.options.componentFn({ ... ref.options.componentOptions, dialogRef: ref, dialogProps: ref.options.dialogProps })
//     }



//     const value: DialogsContextProps = { openViaComponent: openViaComponent, closeAll: closeAll }

//     return (
//         <DialogsContext.Provider value={value}>
//             {dialogs.map((ref) => (
//                 <React.Fragment key={ref.options.idx}>
//                     {renderComponent(ref)}
//                 </React.Fragment>
//             ))}
//             {props.children}
//         </DialogsContext.Provider>
//     )
// }



// import * as React from "react";
// import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@mui/material";

// export const ConfirmDialog = React.forwardRef<
//     DialogElement,
//     ConfirmDialogProps
// >(function ConfirmDialog(props, ref): JSX.Element {
//     const { onClose, onConfirm, ...other } = props;
//     const [state, setState] = React.useState<State>({ open: false });
//     const handleClose = useHandleClose(setState, onClose);
//     const handleConfirm = useHandleConfirm(setState, onConfirm);

//     React.useImperativeHandle(ref, () => ({
//         open() {
//             setState({ open: true });
//         },
//     }));

//     return (
//         <Dialog open={state.open} onClose={handleClose} {...other}>
//             <DialogTitle>Title</DialogTitle>
//             <DialogContent>
//                 Content
//             </DialogContent>
//             <DialogActions>
//                 <Button onClick={handleClose}>Cancel</Button>
//                 <Button onClick={handleConfirm}>OK</Button>
//             </DialogActions>
//         </Dialog>
//     );
// });

// function useHandleClose(setState: SetState, handleClose?: CloseHandler) {
//     return React.useCallback<CloseHandler>(function (event, reason) {
//         setState({ open: false });
//         handleClose?.(event, reason ?? "backdropClick");
//     }, []);
// }

// function useHandleConfirm(setState: SetState, handleConfirm?: ConfirmHandler) {
//     return React.useCallback(async function () {
//         await handleConfirm?.();
//         setState({ open: false });
//     }, []);
// }

// type State = { open: boolean; error?: Error };
// type SetState = React.Dispatch<React.SetStateAction<State>>;
// type CloseHandler = NonNullable<DialogProps["onClose"]>;
// type ConfirmHandler = () => Promise<void> | void;

// export type DialogElement = { open: () => void };

// export type ConfirmDialogProps = Omit<DialogProps, "open"> & {
//     onConfirm?: ConfirmHandler;
// };


import { Dialog } from "@mui/material";
import React from "react";



// export interface OpenDialogProps<T> {
//     children: React.ReactNode
//     afterClose?:(res?:T)=>void
// }

export type DialogParams = {
    children: React.ReactNode;
    open: boolean;
    onClose?: (res?:any) => void;
    onExited?: Function;    
};

type DialogOption = Omit<DialogParams, "open">;
type DialogContainerProps = DialogParams & {
    onClose: () => void;
    onKill: () => void;
};

export interface DialogsContextProps {
    open: (props: DialogOption) => DialogParams,
    close: (res?:any,dialog?: DialogParams) => void,
    closeAll: () => void
}

const DialogContext = React.createContext<DialogsContextProps | undefined>(undefined);

export function useDialog(): DialogsContextProps {
    const ctx: DialogsContextProps | undefined = React.useContext(DialogContext)
    if (!ctx) {
        throw new Error("No se puede utilizar SnackBarContext sin provider")
    }
    return ctx
}


function DialogContainer(props: DialogContainerProps) {
    const { children, open, onClose, onKill } = props;


    const close = () => {
        onClose()
        onKill()
    }

    return (
        <Dialog open={open} onClose={close}>
            {children}
        </Dialog>
    );
}

export default function DialogProvider({ children }: { children: React.ReactNode }) {
    const [dialogs, setDialogs] = React.useState<DialogParams[]>([]);

    const createDialog = (option: DialogOption):DialogParams => {
        const dialog:DialogParams = { ...option, open: true };
        setDialogs((dialogs) => [...dialogs, dialog]);
        return dialog
    };

    const closeDialog = (res?:any,dialog?: DialogParams) => {
        if (dialog) {
            const idx = dialogs.indexOf(dialog)
            if(typeof idx!='number' ){
                return
            }
            dialogs.splice(idx,1)
            if (dialog.onClose) dialog.onClose(res);
            setDialogs([...dialogs])
        } else {
            const latestDialog = dialogs.pop();
            if (!latestDialog) return;
            if (latestDialog.onClose) latestDialog.onClose(res);
            setDialogs([...dialogs])
        }
    };

    const closeAll = () => {
        setDialogs([])
    }; 


    const contextValue: DialogsContextProps = {
        open: createDialog,
        close: closeDialog,
        closeAll: closeAll
    }

    return (
        <DialogContext.Provider value={contextValue}>
            {children}
            {dialogs.map((dialog, i) => {
                const { onClose, ...dialogParams } = dialog;
                const handleKill = () => {
                    // if (dialog.onExited) dialog.onExited();
                    // setDialogs((dialogs) => dialogs.slice(0, dialogs.length - 1));
                };
                return (
                    <DialogContainer
                        key={i}
                        onClose={closeDialog}
                        onKill={handleKill}
                        {...dialogParams}
                    />
                );
            })}
        </DialogContext.Provider>
    );
}