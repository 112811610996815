import { Button } from "@mui/material"
import React from "react"
import TouchableOpacity from "./TouchableOpacity"

export interface FileUploaderProps {
    accept: string
    multiple?: boolean
    children: React.ReactNode
    onFileChange:(file:File|File[])=>void
}

export function FileUploader(props: FileUploaderProps) {

    const fileInputRef = React.useRef(null)

    const handleClick = (ev: any) => {
        (fileInputRef.current as any).click()
    }

    const handleChange = (ev: any) => {
        if(props.multiple){
            props.onFileChange(ev.target.files)
        }else{
            props.onFileChange(ev.target.files[0])
        }        
    }

    return <>
        <TouchableOpacity onClick={handleClick}>
            {props.children}
        </TouchableOpacity>
        <input
            type="file"
            ref={fileInputRef}
            onChange={handleChange}
            multiple={props.multiple || false}
            accept={props.accept}
            style={{ display: 'none' }}
        />
    </>
}