import Parse from 'parse';
import { MapContainerOptions, MapFeatureCollection, ParseQueryParams, ParseQueryResult } from '../constants';
import { PlaceModel } from './place';
import { FeatureItemModel } from './featureItem';

export interface GetPlaceAreasQueryParams extends ParseQueryParams {
    placeId?: string
}



export class PlaceAreaModel extends Parse.Object {

    static getAll(params: GetPlaceAreasQueryParams, extra?: ParseQueryParams): Promise<PlaceAreaModel[]> {
        const query = new Parse.Query(PlaceAreaModel)
        const defaultParams: Partial<GetPlaceAreasQueryParams> = { sort: { field: 'slug', direction: 'asc' } }
        const fullParams: GetPlaceAreasQueryParams & ParseQueryParams = Object.assign({}, defaultParams, extra, params)

        if (fullParams.placeId) {
            const placeQuery = new Parse.Query("Place").equalTo("objectId", fullParams.placeId)
            query.matchesQuery('place', placeQuery)
        }

        if (fullParams.sort) {
            if (fullParams.sort.direction == 'asc') {
                query.ascending(fullParams.sort.field)
            } else {
                query.descending(fullParams.sort.field)
            }
        }

        if (fullParams.limit) {
            query.limit(fullParams.limit)
        }

        return query.find()
    }


    static getById(placeId: string): Promise<PlaceAreaModel | undefined> {
        const query = new Parse.Query(this).equalTo('objectId', placeId)
        return query.first()
    }

    constructor(attr?: object) {
        super('PlaceArea', attr);
    }

    get place(): PlaceModel {
        return this.get('place');
    }

    set place(val: PlaceModel) {
        this.set('place', val);
    }

    get name(): string {
        return this.get('name');
    }

    set name(val: string) {
        this.set('name', val);
    }

    get ateneaId(): string {
        return this.get('ateneaId');
    }

    set ateneaId(val: string) {
        this.set('ateneaId', val);
    }

    get slug(): string {
        return this.get('slug');
    }

    set slug(val: string) {
        this.set('slug', val);
    }

    get mapFile(): Parse.File {
        return this.get('mapFile');
    }

    set mapFile(value: Parse.File) {
        this.set('mapFile', value);
    }

    get mapFileWidth(): number {
        return this.get('mapFileWidth');
    }

    set mapFileWidth(val: number) {
        this.set('mapFileWidth', val);
    }

    get mapFileHeight(): number {
        return this.get('mapFileHeight');
    }

    set mapFileHeight(val: number) {
        this.set('mapFileHeight', val);
    }

    get mapContainerOptions(): MapContainerOptions {
        return this.get('mapContainerOptions');
    }

    set mapContainerOptions(val: MapContainerOptions) {
        this.set('mapContainerOptions', val);
    }

    get geojson(): MapFeatureCollection {
        return this.get('geojson');
    }

    set geojson(value: MapFeatureCollection) {
        this.set('geojson', value);
    }

    getFeatures(): Promise<FeatureItemModel[]> {
        const query = new Parse.Query(FeatureItemModel).equalTo('area', this)
        return query.limit(100000).find({ useMasterKey: true })
    }

    async setMapFile(parseFile:Parse.File):Promise<Parse.File>{
        // const parseFile = new Parse.File(file.name, file);
        await parseFile.save()
        await this.save({mapFile:parseFile})
        return parseFile
    }


}

Parse.Object.registerSubclass('PlaceArea', PlaceAreaModel);